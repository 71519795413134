import React, { Component } from "react";
import { injectIntl, intlShape } from 'react-intl';
import { httpService as http } from "./services/http.service";
import queryString from 'query-string';

class RecoveryPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ok: false,
            error: false,
        };
    }

    async componentDidMount() {
        let error = false;
        let ok = false;
        let query = queryString.parse(this.props.location.search);
        if(query.token) {
            const response = await http.put(`/users/recovery/${query.token}`);
            if (response.ok) {
                    ok = true;
            } else {
                    error = true;
            }
        }
        this.setState({ ok: ok, error: error });
    }

    render() {
        const { ok, error } = this.state;

        const styleValidationContainer = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            background: 'url(\'templates/default/images/img_connexion.jpg\')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }

        const styleValidation = {
            color: '#ffffff',
            fontSize: '1.5em',
            textAlign: 'center'
        }

        return (
            <div style={styleValidationContainer}>
                <div style={styleValidation}>
                    {!ok && !error && <span>{this.props.intl.formatMessage({ id: 'app.password.reset.waiting' })}</span>}
                    {ok && !error && <span>{this.props.intl.formatMessage({ id: 'app.recovery.email.send' })}</span>}
                    {!ok && error && <span>{this.props.intl.formatMessage({ id: 'app.invalid.request' })}</span>}
                </div>
            </div>
        );
    }
}

RecoveryPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(RecoveryPage);
