import {httpService as http} from './http.service';

export const authenticationService = {
    login,
    logout
};

function login(username, password, recaptcha) {
    const data = {
        username,
        password,
        recaptcha
    };

    return http.auth('/manager/token', data)
        .then(response => {
            // login successful if there's a jwt token in the response
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    logout();
                    window.location.reload(true);
                }
            } else {
                // store token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('user', JSON.stringify(response.data.user));
            }
            return response;
        });
}

function logout() {
    // remove token from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('user');
}
