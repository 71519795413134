import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import Intl from './intl.component';
import { createStore } from 'redux';
import reducers from './store/reducers';

const store = createStore(reducers);

render(
        <Provider store={store}>
            <Intl />
        </Provider>,
    document.getElementById('root')
);
